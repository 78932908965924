<template >
  <div>
    <div class="d-flex align-center justify-space-between mb-10 my-8">
      <v-btn icon rounded @click="$router.go(-1)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dense v-bind="attrs" v-on="on">
              <v-icon size="28">mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Volver</span>
        </v-tooltip>
      </v-btn>
      <h1 class="justify-center">Agenda</h1>
      <div></div>
    </div>
    <div>
      <v-row class="">
        <v-card
          width="100%"
          class="ma-4 mb-5 rounded-xl"
          :loading="activeLoadingSearchAtenciondias"
        >
          <div class="pa-3">
            <!-- Título -->
            <v-card-title class="mt-4">
              <h2 class="ml-10 mb-5">Agenda del día</h2>
              <v-spacer></v-spacer>
              <div v-if="licen.Var37 == 1">
                <v-autocomplete
                  v-model="selectDoctor"
                  :items="doctors"
                  item-text="Nombre"
                  return-object
                  outlined
                  dense
                  label="Profesional"
                  class="ma-2"
                ></v-autocomplete>
              </div>
            </v-card-title>
            <!-- Tabla -->
            <div class="pa-3">
              <!-- Buscador -->
              <v-text-field
                class="mb-1"
                v-model="searchAtenciondia"
                append-icon="mdi-magnify"
                color="primary"
                single-line
                autofocus
                hide-details
              ></v-text-field>
              <!-- tabla -->
              <v-data-table
                :headers="headers"
                :items="agendas"
                :search="searchAtenciondia"
                dense
                :footer-props="{
                  'items-per-page-text': 'Filas por página',
                }"
              >
                <!-- Hora -->
                <template v-slot:item.Hora="{ item }">
                  <div width="100%">
                    {{
                      setDateString(
                        item.Fecha.substring(0, 10) +
                          "T" +
                          item.Hora.substring(13, 17)
                      )
                    }}
                  </div>
                </template>
                <!-- Mascota (nombre) -->
                <template v-slot:item.Nombre="{ item }">
                  <div class="d-flex align-center">
                    <h3 class="my-3 primary--text">{{ item.Nombre }}</h3>
                    <v-avatar
                      v-if="item.Monto == '&H80FF80'"
                      class="ml-3"
                      size="20"
                    >
                      <v-icon color="green">mdi-bookmark-check-outline</v-icon>
                    </v-avatar>
                  </div>
                </template>
                <!-- Motivo -->
                <template v-slot:item.Fecha="{ item }">
                  <div>{{ item.Fecha }} a las {{ item.Hora }}</div>
                </template>
                <template v-slot:item.Estado="{ item }">
                  <div>
                    <div v-if="item.Estado == 0">
                      <v-chip small color="primary" outlined>Pendiente</v-chip>
                    </div>
                    <div v-if="item.Estado == 1">
                      <v-chip small color="primary" outlined>Confirmada</v-chip>
                    </div>
                    <div v-if="item.Estado == 2">
                      <v-chip small color="primary" outlined>Anulada</v-chip>
                    </div>
                  </div>
                </template>
                <!-- Acción -->
                <template v-slot:item.action="{ item }">
                  <div class="actions">
                    <!-- Eliminar visita -->

                    <!-- Ver visita (redirige a vista Ficha médica ) -->
                    <v-btn icon @click="btnActivateCreateAtencion(item)">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </template>
                <!-- Mensaje en caso de no haber datos para mostrar -->
                <template v-slot:no-data>
                  <v-alert :value="true">
                    No hay resultados disponibles
                  </v-alert>
                </template>
              </v-data-table>
              <!-- dialogo de eliminacion -->
            </div>
          </div>
        </v-card>
        <v-dialog v-model="activeCreateAtencion" persistent max-width="490">
          <v-card>
            <v-card-title class="text-h5">
              ¿Seguro que quiere crear una atención <br />
              desde la agenda?
            </v-card-title>
            <v-card-text
              >Está acción no se puede deshacer y creara una nueva visita, debe
              crearla cuando esté seguro y el cliente se encuentre en el
              lugar</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" outlined @click="btnCancelCreateAtencion">
                Cancelar
              </v-btn>
              <v-btn
                :loading="activeLoadingCreateAgenda"
                color="primary"
                @click="btnCreateAtenciondiaFromAgenda"
              >
                Crear
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      headers: [
        {
          text: " Ficha",
          value: "Ficha",
        },
        {
          text: "Mascota",
          align: "start",
          sortable: false,
          value: "Mascota",
        },
        {
          text: "Servicio",
          value: "Servicio",
        },
        {
          text: "Fecha",
          value: "Fecha",
        },
        {
          text: "Estado",
          value: "Estado",
        },
        { text: "Crear atención", value: "action" },
      ],
      selectDoctor: "",
      atenciondia: {
        Rut: "",
        Dig: "",
        Nombre: "",
        Fichan: 0,
        Fechahora: "",
        Hora: "",
        Pago: "",
        Bonon: "",
        Monto: "",
        Informe: "",
        Reg: "",
        Rutdoc: "",
        Nombredoc: "",
        Fecha: "",
        Sucursal: "",
        Tipo: "",
      },
      activeCreateAtencion: false,
      selectAgenda: {},
      activeLoadingCreateAgenda: false,
    };
  },
  watch: {
    selectDoctor: function (val) {
      this.getAgendas({
        fecha: new Date().toISOString().substring(0, 10),
        doctor: val.Rut,
      });
    },
    doctor: function(val){
      this.getAgendas({fecha: new Date().toISOString().substring(0,10), doctor: this.doctor.Rut})
    },
    successCreateAtenciondia: function (val) {
      if (val) {
        this.activeCreateAtencion = false;
        this.selectAgenda = {};
        this.activeLoadingCreateAgenda = false;
          setTimeout(() => {
        this.$router.push("/medical/records");
      }, 400);
      }
    },
  },
  computed: {
    ...mapState("medical-module", [
      "agendas",
      "doctor",
      "doctors",
      "atenciondia",
      "successCreateAtenciondia"
    ]),
    ...mapState("auth-module", ["licen", "user"]),
    ...mapState("customers-module", ["cliente"]),
  },
  methods: {
    ...mapActions("medical-module", [
      "getAgendas",
      "postAtenciondia",
      "doctor",
    ]),
    ...mapActions("customers-module", ["getClienteByFichan"]),
    setDateNow() {
      var currentDateObj = new Date();
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 180000;
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      return newDateObj;
    },
    async btnCreateAtenciondiaFromAgenda() {
      this.activeLoadingCreateAgenda = true;
      let today = this.setDateNow();
      await this.getClienteByFichan(this.selectAgenda.Ficha);
      let length_rut = this.cliente.Rut.length;
      this.atenciondia.Rut = this.cliente.Rut.substring(0, length_rut - 2);
      this.atenciondia.Dig = this.cliente.Rut.slice(-1);
      this.atenciondia.Pago = this.selectAgenda.Especialidad;
      this.atenciondia.Nombre = this.cliente.Nombre;
      this.atenciondia.Fichan = this.cliente.Fichan;
      this.atenciondia.Fechahora = today.toISOString().substring(0, 10);
      this.atenciondia.Hora =
        today.toISOString().substring(0, 10) +
        " " +
        today.toISOString().substring(11, 19);
      this.atenciondia.Bonon = "0";
      this.atenciondia.Monto = "0";
      this.atenciondia.Informe = ".";
      this.atenciondia.Reg = "0";
      this.atenciondia.Rutdoc = this.doctor.Rut;
      this.atenciondia.Nombredoc = this.doctor.Nombre;
      this.atenciondia.Fecha = today.toISOString().substring(0, 10);
      this.atenciondia.Sucursal = this.user.Sucursal;
      this.atenciondia.Tipo = "V";
      this.postAtenciondia({ atenciondia: this.atenciondia });
    },
    btnActivateCreateAtencion(agenda) {
      this.activeCreateAtencion = true;
      this.selectAgenda = agenda;
    },
    btnCancelCreateAtencion() {
      this.activeCreateAtencion = false;
      this.selectAgenda = {};
    },
    async loadAgenda(){
      // console.log("cargando agenda", this.doctor.Rut)
      await this.getAgendas({fecha: new Date().toISOString().substring(0,10), doctor: this.doctor.Rut})
    },
  },
  created(){
    this.loadAgenda()
  }
};
</script>
<style >
</style>